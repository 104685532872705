import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './styles.less';

import { Modal, Button, Table, Card, Row, Col, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { announcementList, announcementDetail } from 'apis/announcements';
import AnnouncementCreateForm from './forms/announcement-create.form';
import AnnouncementUpdateForm from './forms/announcement-update.form';

import useToken from 'hooks/useToken';

const Announcement = () => {
  const { token } = useToken();

  const [tags, setTags] = useState([])

  const [isCreateModal, setIsCreateModal] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [announcement, setAnnouncement] = useState({});
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    (async () => {
      if (token) {
        try {
          setLoading(true)
          const [announcements] = await Promise.all([
            announcementList(),
          ]);

          setAnnouncements(announcements)
          setTags(tags)
          setLoading(false)
        } catch (e) {
          setLoading(false)
        }
      }
    })();
  }, [token]);

  const openUpdateModal = (announcementId) => {
    const announcement = announcements.find(item => item.announcementId === announcementId);
    setAnnouncement(announcement);
    setIsUpdateModal(true);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    {
      title: 'Title',
      width: '15%',
      render: (record) => {
        return (
          <div>{record?.titleEn}</div>
        )
      }
    },
    {
      title: 'Created date',
      align: 'center',
      dataIndex: 'createdAt',
      width: '7%',
      render: (text, record, index) => {
        return (
          `${moment(record.createdAt).format('YYYY-MM-DD')}`
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      align: 'center',
      width: '4%',
      render: (record) => {
        if (record === true)
          return (
            <Tag>Active</Tag>
          )
        else {
          return (
            <Tag color={'red'}>In-active</Tag>
          )
        }
      }
    },
    {
      title: '',
      align: 'center',
      width: '6%',
      render: (record) => {
        return (
          <Button type="primary" onClickCapture={() => openUpdateModal(record.announcementId)}>Edit</Button>
        );
      }
    }
  ];

  return (
    <>
      <Card style={{ marginBottom: 18 }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
          <Col className="gutter-row" span={12}>
            <h2>Announcement</h2>
          </Col>
          <Col className="gutter-row" span={12} style={{ textAlign: 'right' }}>
            <Button type="primary" onClick={() => setIsCreateModal(true)} icon={<PlusOutlined />}>Add new</Button>
          </Col>
        </Row>
      </Card>
      <Table
        loading={loading}
        bordered
        columns={columns}
        dataSource={announcements}
        onChange={(pagination, filters, sorter) => {
          setCurrentPage(pagination.current);
        }}
        pagination={{
          total: announcements.lenght,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
          pageSize: 20,
          current: currentPage
        }}
      />
      <Modal
        open={isCreateModal}
        title="Announcement / Create"
        onCancel={() => setIsCreateModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <AnnouncementCreateForm
          token={token}
          onCancel={() => setIsCreateModal(false)}
          onCallback={async () => {
            setLoading(true)
            const [announcements] = await Promise.all([
              announcementList(),
            ]);
  
            setAnnouncements(announcements)
            setLoading(false)
            setIsUpdateModal(false)

            setIsCreateModal(false)
          }}
        />
      </Modal>
      <Modal
        open={isUpdateModal}
        title="Announcement / Update"
        onCancel={() => setIsUpdateModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <AnnouncementUpdateForm
          token={token}
          announcement={announcement}
          onCancel={() => setIsUpdateModal(false)}
          onCallback={async () => {
            setLoading(true)
            const [announcements] = await Promise.all([
              announcementList(),
            ]);
  
            setAnnouncements(announcements)
            setLoading(false)
            setIsUpdateModal(false)
          }}
        />
      </Modal>
    </>
  )
};

export default Announcement;