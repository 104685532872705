/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';

import { Layout, Button, Table, DatePicker, Modal, Row, Col, Input, Card, Space, Tag, Radio, Statistic, message, Select } from 'antd';
import { SearchOutlined, DownloadOutlined, ExclamationCircleOutlined, DeleteFilled, CaretRightOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import useAuth from 'hooks/useAuth';
import { userList, userDowngradeFree, userUpgradePremium, userDelete, runScheduleDowngradeFreeWhenExpired } from 'apis/user';
import { Line } from '@ant-design/charts';
import { useNavigate } from 'react-router-dom';

import UserUpdateForm from './forms/user-update.form';
import _ from 'lodash';
import moment from 'moment';

import './styles.less';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx-js-style';

const { RangePicker } = DatePicker;

const Users = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState([]);

  const searchInputEl = useRef(null);
  const [, setUsers] = useState([]);

  const [usersFilter, setUsersFilter] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [, setSearchedColumn] = useState('');

  const [fromDate, setFromDate] = useState(moment().subtract(3, 'months'));
  const [toDate, setToDate] = useState(moment());

  const [chartData, setChartData] = useState([]);
  const [chartData2, setChartData2] = useState([]);
  // const [chartData3, setChartData3] = useState([]);
  const [chartType, setChartType] = useState("Daily");
  const [chartType2, setChartType2] = useState("Daily");
  // const [chartType3, setChartType3] = useState("Daily");

  // const [appsumo, setAppSumo] = useState([]);
  const [source, setSource] = useState();
  const [email, setEmail] = useState("");

  const [userUpdate, setUserUpdate] = useState({});
  const [isUpdateModal, setIsUpdateModal] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        //setLoading(true)
        if (user) {
          //fetch data
          setLoading(true)
          let payload = {
            fromDate: fromDate.format('YYYY-MM-DD'),
            toDate: toDate.format('YYYY-MM-DD'),
            source,
          };
          const [result] = await Promise.all([
            userList(payload),
          ]);

          setUsers(result.map(x => {
            if (x.isFirstLogin === false || x.isFirstLogin === undefined) {
              return {
                ...x,
                isFirstLogin: false,
              }
            } else {
              return x
            }
          }))
          setUsersFilter(result.map(x => {
            if (x.isFirstLogin === false || x.isFirstLogin === undefined) {
              return {
                ...x,
                isFirstLogin: false,
              }
            } else {
              return x
            }
          }))
          setLoading(false)
        }
      } catch (e) {
        setLoading(false)
      }
    })();
  }, [user])

  useEffect(() => {
    if (usersFilter) {

      const type = chartType === "Monthly" ? 'month' : chartType === "Weekly" ? 'week' : 'day';
      const dateFormat = type === 'month' ? "YYYY-MM" : type === 'day' ? "YYYY-MM-DD" : "YYYY-[W]WW";
      let days = {};

      let date = moment(toDate);
      while (moment(fromDate).isBefore(moment(date), type)) {
        days[date.format(dateFormat)] = {
          payUsers: 0,
          unpayUsers: 0,
          totalUsers: 0,
        };

        date = date.subtract(1, type);
      }

      days[date.format(dateFormat)] = {
        payUsers: 0,
        unpayUsers: 0,
        totalUsers: 0,
      };

      usersFilter.forEach(user => {
        const date = moment(user.createdAt).format(dateFormat);
        let isInvalid = false;

        if (fromDate && toDate) {
          isInvalid = moment(user.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(user.createdAt).isAfter(toDate.clone().endOf("day"));
        }

        if (!isInvalid) {
          if (date in days) {
            if (user.role === "pre" && user?.isGiveAway === undefined) {
              days[date].payUsers++;
            } else {
              days[date].unpayUsers++;
            }

            days[date].totalUsers++;
          }
        }
      });

      setChartData(_.flatten(Object.keys(days).reverse().map(item => {
        return [
          {
            date: item,
            value: days[item].payUsers,
            category: "Paid"
          },
          {
            date: item,
            value: days[item].unpayUsers,
            category: "Unpaid"
          },
          {
            date: item,
            value: days[item].totalUsers,
            category: "Total"
          },
        ]
      })));
    }
  }, [chartType, usersFilter]);

  useEffect(() => {
    if (usersFilter) {
      const type = chartType2 === "Monthly" ? 'month' : chartType2 === "Weekly" ? 'week' : 'day';
      const dateFormat = type === 'month' ? "YYYY-MM" : type === 'day' ? "YYYY-MM-DD" : "YYYY-[W]WW";
      let days = {};

      let date = moment(toDate);
      while (moment(fromDate).isBefore(moment(date), type)) {
        days[date.format(dateFormat)] = {
          useUsers: 0,
          unuseUsers: 0,
          totalUsers: 0,
        };

        date = date.subtract(1, type);
      }

      days[date.format(dateFormat)] = {
        useUsers: 0,
        unuseUsers: 0,
        totalUsers: 0,
      };

      usersFilter.forEach(user => {
        const date = moment(user.createdAt).format(dateFormat);
        let isInvalid = false;

        if (fromDate && toDate) {
          isInvalid = moment(user.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(user.createdAt).isAfter(toDate.clone().endOf("day"));
        }

        if (!isInvalid) {
          if (date in days) {
            if (user.isUsages === true) {
              days[date].useUsers++;
            } else {
              days[date].unuseUsers++;
            }

            days[date].totalUsers++;
          }
        }
      });

      setChartData2(_.flatten(Object.keys(days).reverse().map(item => {
        return [
          {
            date: item,
            value: days[item].useUsers,
            category: "Used"
          },
          {
            date: item,
            value: days[item].unuseUsers,
            category: "Unused"
          },
          {
            date: item,
            value: days[item].totalUsers,
            category: "Total"
          },
        ]
      })));
    }
  }, [chartType2, usersFilter]);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInputEl}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      const [firstValue, ...restValue] = value;

      if (dataIndex === "usernameReferral" && firstValue === "!") {
        return record[dataIndex]
          ? !record[dataIndex].toString().toLowerCase().includes(restValue.join("").toLowerCase())
          : true;
      }

      return record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '';
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputEl.current.select(), 100);
      }
    },
    render: text => {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <div>
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text[dataIndex] ? (dataIndex === "source" ? `${text[dataIndex].charAt(0).toUpperCase()}${text[dataIndex].slice(1)}`.toString() : text[dataIndex].toString()) : ''}
              />
            </div>
          </div>
        </div>
      )
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm();
  };

  function onChange(dates, dateStrings) {
    if (!dates) {
      setFromDate(undefined);
      setToDate(undefined);
    }

    if (dates && dates[0]) {
      setFromDate(dates[0])
    }

    if (dates && dates[1]) {
      setToDate(dates[1])
    }
  }

  const columns = [
    {
      title: 'Country',
      align: 'center',
      width: '5%',
      ...getColumnSearchProps('countryCode'),
    },
    {
      title: 'Created At',
      align: 'center',
      dataIndex: 'createdAt',
      width: '10%',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        return (
          <div>
            <div>{moment(record.createdAt).format('YYYY-MM-DD HH:mm')}</div>
          </div>
        );
      }
    },
    {
      title: 'Email',
      width: '18%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Role',
      align: 'center',
      dataIndex: 'role',
      render: (record) => {
        if (record === 'free') {
          return <Tag>{record?.toUpperCase()}</Tag>;
        } else {
          return <Tag color="green">{record?.toUpperCase()}</Tag>;
        }
      },
      width: '8%',
      filterMultiple: true,
      filters: [
        {
          text: 'Free',
          value: 'free',
        },
        {
          text: 'Pre',
          value: 'pre',
        },
      ],
      onFilter: (value, record) => record.role === value,
    },
    {
      title: 'Source',
      align: 'center',
      dataIndex: 'source',
      render: (record) => {
        if (record) {
          if (record === 'manual-invoice') {
            return <Tag color="green">Manual Invoice</Tag>;
          } else {
            return <Tag>{record?.toUpperCase()}</Tag>;
          }
        }
      },
      width: '8%',
      filterMultiple: true,
      filters: [
        {
          text: 'Manual invoice',
          value: 'manual-invoice',
        },
      ],
      onFilter: (value, record) => record.source === value,
    },
    {
      title: 'Seat',
      align: 'center',
      render: (record) => {
        return <div>{record?.source === "manual-invoice" ? record.seat : record?.subscription?.quantity}</div>
      },
      width: '8%',
    },
    {
      title: 'EndTime',
      align: 'center',
      dataIndex: 'endTime',
      width: '10%',
      sorter: (a, b) => moment(a.endTime).unix() - moment(b.endTime).unix(),
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        return (
          <div>
            <div>{moment(record.endTime).format('YYYY-MM-DD HH:mm')}</div>
          </div>
        );
      }
    },
    {
      title: 'Usages',
      align: 'center',
      dataIndex: 'isUsages',
      render: (record) => {
        if (record === true) {
          return <Tag color="green">Yes</Tag>;
        } else {
          return <Tag color="red">No</Tag>;
        }
      },
      width: '8%',
      filterMultiple: true,
      filters: [
        {
          text: 'Used',
          value: true,
        },
        {
          text: 'Unused',
          value: false,
        },
      ],
      onFilter: (value, record) => {
        return record.isUsages === value;
      },
    },
    {
      title: 'Expired',
      align: 'center',
      dataIndex: 'isAfter',
      render: (record) => {
        if (record === false) {
          return <Tag color="red">Expired</Tag>;
        }
      },
      width: '8%',
      filterMultiple: true,
      filters: [
        {
          text: 'Expired',
          value: false,
        },
      ],
      onFilter: (value, record) => record.isAfter === value,
    },
    {
      title: 'First Login',
      align: 'center',
      dataIndex: 'isFirstLogin',
      render: (record) => {
        if (record === false) {
          return <Tag color="red">No</Tag>;
        }
      },
      width: '8%',
      filterMultiple: true,
      filters: [
        {
          text: 'No',
          value: false,
        },
        {
          text: 'Yes',
          value: true,
        },
      ],
      onFilter: (value, record) => record.isFirstLogin === value,
    },
    {
      align: 'center',
      width: '8%',
      render: (record) =>
        <div>
          {(user?.role === 'super') && (
            <>
              <Button
                type='link'
                ghost
                disabled={record?.role === 'pre'}
                onClick={() => {
                  Modal.confirm({
                    title: 'Confirm',
                    icon: <ExclamationCircleOutlined />,
                    content: 'Are you sure to upgrade premium',
                    okText: 'Ok',
                    cancelText: 'No',
                    onOk: () => {
                      let userId = record?.userId;
                      let payload = {
                        userId,
                      }
                      userUpgradePremium(payload)
                        .then(async (result) => {
                          setLoading(true)
                          message.success('Upgrade premium is successful')

                          setLoading(true)
                          let usersFilterUpdate = usersFilter.map(item => {
                            if (item.userId === record?.userId) {
                              return {
                                ...item,
                                role: 'pre',
                                source: 'giveaway',
                                endTime: moment().clone().add(1, 'month'),
                              }
                            } else {
                              return item
                            }
                          })
                          setUsersFilter(usersFilterUpdate)
                          setLoading(false)
                        })
                        .catch(err => console.log(err))
                    }
                  });
                }}
              >
                Upgrade
              </Button>
              <Button
                type='link'
                ghost
                disabled={record?.role === 'free'}
                onClick={() => {
                  Modal.confirm({
                    title: 'Confirm',
                    icon: <ExclamationCircleOutlined />,
                    content: 'Are you sure to downgrade free?',
                    okText: 'Ok',
                    cancelText: 'No',
                    onOk: () => {
                      let userId = record?.userId;
                      let payload = {
                        userId,
                      }
                      userDowngradeFree(payload)
                        .then(async () => {
                          message.success('Downgrade free is successful')

                          setLoading(true)
                          let usersFilterUpdate = usersFilter.map(item => {
                            if (item.userId === record?.userId) {
                              return {
                                ...item,
                                role: 'free',
                                endTime: moment().clone(),
                                source: null,
                                seat: null
                              };
                            } else {
                              return item;
                            }
                          })
                          setUsersFilter(usersFilterUpdate)
                          setLoading(false)
                        })
                        .catch(err => console.log(err))
                    }
                  });
                }}
              >
                Downgrade
              </Button>
            </>
          )}
        </div>
    },
    {
      align: 'center',
      render: (record) => {
        return (user?.role === 'super') && (
          <div>
            <Button
              type='link'
              onClick={() => {
                navigate(`/v1/users/usage-detail/${record.userId}`)
              }}
            >
              View Usage
            </Button>
            <Button
              type='link'
              disabled={record?.role === 'free'}
              onClick={() => {
                const user = usersFilter.find(
                  (item) => item.userId === record.userId
                );
                setUserUpdate(user);
                setIsUpdateModal(true);
              }}
            >
              Edit
            </Button>
            <Button
              style={{ color: 'red' }}
              type='link'
              ghost
              onClick={() => {
                Modal.confirm({
                  title: 'Confirm',
                  icon: <DeleteFilled />,
                  content: 'Are you sure to delete this user?',
                  okText: 'Ok',
                  cancelText: 'No',
                  onOk: () => {
                    let { userId, email } = record;
                    let payload = {
                      userId,
                      email,
                    }
                    userDelete(payload)
                      .then(async () => {
                        setLoading(true)
                        message.success('This user has been deleted successfully')

                        setLoading(true)
                        let payload = {
                          fromDate: fromDate.format('YYYY-MM-DD'),
                          toDate: toDate.format('YYYY-MM-DD'),
                          source,
                        };
                        const [users] = await Promise.all([
                          userList(payload)
                        ]);

                        setUsers(users)
                        setUsersFilter(users)
                        setLoading(false)
                      })
                      .catch(err => console.log(err))
                  }
                });
              }}
            >
              Delete
            </Button>
          </div>
        );
      },
      width: '8%',
    },
  ];

  const config = {
    loading: loading,
    data: chartData,
    xField: 'date',
    yField: 'value',
    seriesField: 'category',
    yAxis: {
      label: {
        formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    tooltip: {
      showTitle: true,
      title: (value) => {
        if (chartType === "Weekly") {
          const date = value.split("W");

          if (date.length === 2) {
            const fromDate = moment().day("Monday").isoWeek(date[1]).format("YYYY-MM-DD");
            const toDate = moment().day("Sunday").isoWeek(date[1]).format("YYYY-MM-DD");

            return `${value} \n\n (${fromDate} -> ${toDate})`;
          } else {
            return value;
          }

        } else {
          return value;
        }
      },
    },
  };

  const config2 = {
    loading: loading,
    data: chartData2,
    xField: 'date',
    yField: 'value',
    seriesField: 'category',
    yAxis: {
      label: {
        formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    tooltip: {
      showTitle: true,
      title: (value) => {
        if (chartType === "Weekly") {
          const date = value.split("W");

          if (date.length === 2) {
            const fromDate = moment().day("Monday").isoWeek(date[1]).format("YYYY-MM-DD");
            const toDate = moment().day("Sunday").isoWeek(date[1]).format("YYYY-MM-DD");

            return `${value} \n\n (${fromDate} -> ${toDate})`;
          } else {
            return value;
          }
        } else {
          return value;
        }
      },
    },
  };

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }} >
        <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col className="gutter-row" span={12}>
              <h2>Users</h2>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <RangePicker
                ranges={{
                  'Today': [moment(), moment()],
                  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                  'This Week': [moment().startOf('week'), moment().endOf('week')],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                  'This Quarter': [moment().startOf('quarter'), moment().endOf('quarter')],
                  'This Year': [moment().startOf('year'), moment().endOf('year')],
                }}
                onChange={onChange}
                defaultValue={[moment().subtract(3, 'months'), moment()]}
                allowClear={false}
              />

              <Select
                placeholder="Source"
                style={{ width: 100, marginLeft: 16 }}
                value={source}
                onChange={(value) => {
                  setSource(value);
                }}
              >
                <Select.Option value="appsumo">Appsumo</Select.Option>
                <Select.Option value="others">Others</Select.Option>
                <Select.Option value="all">All</Select.Option>
              </Select>
              <Input
                placeholder="Email"
                style={{ width: 120, marginLeft: 16 }}
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
              />

              <Button
                type="primary"
                style={{ marginLeft: 24 }}
                icon={<SearchOutlined />}
                disabled={!fromDate || !toDate}
                onClick={() => {
                  setLoading(true);
                  const payload = {
                    fromDate: fromDate.format('YYYY-MM-DD'),
                    toDate: toDate.format('YYYY-MM-DD'),
                    source,
                  };

                  if (email) {
                    payload.email = email ? email : null;
                  }

                  userList(payload)
                    .then(result => {
                      setUsersFilter(result);
                      setLoading(false);
                    })
                    .catch(err => {
                      setLoading(false);
                    })
                }}
              >
                Search
              </Button>


              {/* <Button
                style={{ float: 'right' }}
                type={'link'}
                icon={<DownloadOutlined />}
                onClick={() => {
                  const totalUsers = appsumo.reduce((prev, item) => {
                    return prev + item.users;
                  }, 0);
                  const templateData = totalUsers > 0 ? [...appsumo, {
                    countryCode: "Total",
                    users: totalUsers,
                  }] : [...appsumo];

                  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                  const fileExtension = '.xlsx';
                  const ws = XLSX.utils.json_to_sheet(templateData);
                  const wb = { Sheets: { Data: ws }, SheetNames: ['Data'] };

                  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                  const data = new Blob([excelBuffer], { type: fileType });
                  const fileName = `users_appsumo_${moment().format("MMMM_Do_YYYY_h_mm").trim()}`;
                  FileSaver.saveAs(data, fileName + fileExtension);
                }}
              >
                Download2
              </Button> */}
              <Button
                style={{ float: 'right' }}
                type={'link'}
                icon={<CaretRightOutlined />}
                onClick={() => {
                  runScheduleDowngradeFreeWhenExpired()
                    .then(result => {
                      message.success('Done')
                      console.log('result: ', result)
                    })
                    .catch(err => {
                      console.log('err: ', err)
                      message.error('Error')
                    })
                }}
              >
                Downgrade Schedule (Manually)
              </Button>
              <Button
                style={{ float: 'right' }}
                type={'link'}
                icon={<DownloadOutlined />}
                onClick={() => {
                  const templateData = usersFilter.map(item => {
                    return {
                      'Created At': item?.createdAt,
                      'ID': item?.userId,
                      'Email': item?.email,
                      'Role': item?.role?.toUpperCase(),
                      'VIP': item?.isVip ? 'X' : ''
                    };
                  });

                  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                  const fileExtension = '.xlsx';
                  const ws = XLSX.utils.json_to_sheet(templateData);
                  const wb = { Sheets: { Data: ws }, SheetNames: ['Data'] };

                  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                  const data = new Blob([excelBuffer], { type: fileType });
                  const fileName = `users_${moment().format("MMMM_Do_YYYY_h_mm").trim()}`;
                  FileSaver.saveAs(data, fileName + fileExtension);
                }}
              >
                Download
              </Button>
              {/* <Button
                  style={{ float: 'right' }}
                  type={'link'}
                  icon={<RetweetOutlined />}
                  onClick={async () => {
                    await Promise.all(usersFilter.map(async item => {
                      let usage = await getUsages({
                        email: item?.email
                      });

                      // //update firebase
                      // let user = {
                      //   userId: item?.userId,
                      //   isUsage: usage.data.length > 0 ? true : false,
                      // };
                      // userUpdate(user);

                      //update sendgrid
                      let {role, email, createdAt, startTime, endTime, isGiveAway, countryCode} = item;
                      let userSendgrid = {
                        role, 
                        email, 
                        createdAt: createdAt ? moment(createdAt).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY'), 
                        startTime: startTime ? moment(startTime).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY'),
                        endTime: endTime ? moment(endTime).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY'), 
                        isGiveAway, 
                        countryCode,
                        isUsage: usage.data.length > 0 ? 1 : 0,
                      };
                      userSyncToSendgrid(userSendgrid)
                    }))
                    alert('Sync to Sendgrid is successful')
                  }}
                >Sync to Sendgrid</Button> */}
            </Col>
          </Row>
        </Card>

        <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between" style={{ marginBottom: 24 }}>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Total"
                  loading={loading}
                  // value={usersFilter?.length}
                  value={usersFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid;
                  })?.length}
                  valueStyle={{
                    color: '#4a90e2',
                    fontWeight: 'bold'
                  }}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Paid"
                  loading={loading}
                  // value={usersFilter.filter(x => x.role === 'pre' && x.isGiveAway === undefined)?.length}
                  value={usersFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid && x.role === 'pre' && x.isGiveAway === undefined;
                  })?.length}
                  valueStyle={{
                    color: '#333',
                    fontWeight: 'bold'
                  }}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Unpaid"
                  loading={loading}
                  // value={usersFilter.filter(x => x.role !== 'pre' || x.isGiveAway !== undefined)?.length}
                  value={usersFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid && (x.role !== 'pre' || x.isGiveAway !== undefined);
                  })?.length}
                  valueStyle={{
                    color: '#333',
                    fontWeight: 'bold'
                  }}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Percentage of paid users / total users"
                  loading={loading}
                  // value={usersFilter.filter(x => x.role !== 'pre' || x.isGiveAway !== undefined)?.length}
                  value={usersFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid;
                  })?.length ? _.round((usersFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid && x.role === 'pre' && x.isGiveAway === undefined;
                  })?.length) * 100 / (usersFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid;
                  })?.length), 2) : 0}
                  valueStyle={{
                    color: '#333',
                    fontWeight: 'bold'
                  }}
                  suffix="%"
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col span={24}>
              <Card style={{ display: 'block' }}>
                <div style={{ marginBottom: '20px' }}>
                  <Radio.Group value={chartType} onChange={(e) => setChartType(e.target.value)}>
                    <Radio.Button style={{ marginRight: '8px' }} value="Daily">Daily</Radio.Button>
                    <Radio.Button style={{ marginRight: '8px' }} value="Weekly">Weekly</Radio.Button>
                    <Radio.Button style={{ marginRight: '16px' }} value="Monthly">Monthly</Radio.Button>
                  </Radio.Group>
                </div>
                <Line {...config} />
              </Card>
            </Col>
          </Row>
        </Card>

        <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between" style={{ marginBottom: 24 }}>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Total"
                  loading={loading}
                  // value={usersFilter?.length}
                  value={usersFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid;
                  })?.length}
                  valueStyle={{
                    color: '#4a90e2',
                    fontWeight: 'bold'
                  }}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Used"
                  loading={loading}
                  // value={usersFilter.filter(x => x.role === 'pre' && x.isGiveAway === undefined)?.length}
                  value={usersFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid && x.isUsages === true;
                  })?.length}
                  valueStyle={{
                    color: '#333',
                    fontWeight: 'bold'
                  }}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Unused"
                  loading={loading}
                  // value={usersFilter.filter(x => x.role !== 'pre' || x.isGiveAway !== undefined)?.length}
                  value={usersFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid && x.isUsages !== true;
                  })?.length}
                  valueStyle={{
                    color: '#333',
                    fontWeight: 'bold'
                  }}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Percentage of used users / total users"
                  loading={loading}
                  // value={usersFilter.filter(x => x.role !== 'pre' || x.isGiveAway !== undefined)?.length}
                  value={usersFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid;
                  })?.length ? _.round((usersFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid && x.isUsages === true;
                  })?.length) * 100 / (usersFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid;
                  })?.length), 2) : 0}
                  valueStyle={{
                    color: '#333',
                    fontWeight: 'bold'
                  }}
                  suffix="%"
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col span={24}>
              <Card style={{ display: 'block' }}>
                <div style={{ marginBottom: '20px' }}>
                  <Radio.Group value={chartType2} onChange={(e) => setChartType2(e.target.value)}>
                    <Radio.Button style={{ marginRight: '8px' }} value="Daily">Daily</Radio.Button>
                    <Radio.Button style={{ marginRight: '8px' }} value="Weekly">Weekly</Radio.Button>
                    <Radio.Button style={{ marginRight: '16px' }} value="Monthly">Monthly</Radio.Button>
                  </Radio.Group>
                </div>
                <Line {...config2} />
              </Card>
            </Col>
          </Row>
        </Card>

        {/* <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between" style={{ marginBottom: 24 }}>
            <Col span={8}>
              <Card>
                <Statistic
                  title="Paid"
                  loading={loading}
                  // value={usersFilter.filter(x => x.role !== 'pre' || x.isGiveAway !== undefined)?.length}
                  value={usersFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid && x.role === 'pre' && x.isGiveAway === undefined;
                  })?.length}
                  valueStyle={{
                    color: '#333',
                    fontWeight: 'bold'
                  }}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic
                  title="Used"
                  loading={loading}
                  // value={usersFilter.filter(x => x.role === 'pre' && x.isGiveAway === undefined)?.length}
                  value={usersFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid && x.role === 'pre' && x.isGiveAway === undefined && x.isUsages === true;
                  })?.length}
                  valueStyle={{
                    color: '#333',
                    fontWeight: 'bold'
                  }}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic
                  title="Percentage of used users / paid users"
                  loading={loading}
                  // value={usersFilter.filter(x => x.role !== 'pre' || x.isGiveAway !== undefined)?.length}
                  value={usersFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid;
                  })?.length ? _.round((usersFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid && x.role === 'pre' && x.isGiveAway === undefined && x.isUsages === true;
                  })?.length) * 100 / (usersFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid && x.role === 'pre' && x.isGiveAway === undefined;
                  })?.length), 2) : 0}
                  valueStyle={{
                    color: '#333',
                    fontWeight: 'bold'
                  }}
                  suffix="%"
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col span={24}>
              <Card style={{ display: 'block' }}>
                <div style={{ marginBottom: '20px' }}>
                  <Radio.Group value={chartType3} onChange={(e) => setChartType3(e.target.value)}>
                    <Radio.Button style={{ marginRight: '8px' }} value="Daily">Daily</Radio.Button>
                    <Radio.Button style={{ marginRight: '8px' }} value="Weekly">Weekly</Radio.Button>
                    <Radio.Button style={{ marginRight: '16px' }} value="Monthly">Monthly</Radio.Button>
                  </Radio.Group>
                </div>
                <Line {...config3} />
              </Card>
            </Col>
          </Row>
        </Card> */}

        <Table
          loading={loading}
          bordered
          columns={columns.filter(column => {
            if (column.key === "action") {
              if (user?.data?.role === "super" || user?.data?.role === "sale") {
                return true;
              }
              return false;
            }
            return true;
          })}
          dataSource={usersFilter}
          pagination={{
            defaultPageSize: 20
          }}
        />

        <Modal
          open={isUpdateModal}
          title={`User / Edit`}
          onCancel={() => setIsUpdateModal(false)}
          destroyOnClose={true}
          width={980}
          centered
          footer={null}
        >
          <UserUpdateForm
            user={userUpdate}
            onCancel={() => setIsUpdateModal(false)}
            onCallback={async (endTime, source, seat) => {
              setLoading(true);

              let usersFilterUpdate = usersFilter.map(item => {
                if (item.userId === userUpdate.userId) {
                  return {
                    ...item,
                    endTime: moment(endTime),
                    source: source,
                    isAfter: moment(endTime).isAfter(new moment()),
                    seat
                  };
                } else {
                  return item
                }
              })
              setUsersFilter(usersFilterUpdate)
              setLoading(false)

              setIsUpdateModal(false)
            }}
          />
        </Modal>
      </Layout>
    </Layout>
  )
};

export default Users;