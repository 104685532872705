import { db } from 'utils/firebase';
import { collection, query, doc, orderBy, getDocs, getDoc, addDoc, updateDoc, deleteDoc, serverTimestamp  } from "firebase/firestore";

export const postList = async () => {
  const q = query(collection(db, "posts"), 
    orderBy('no', 'desc')
  );
  const querySnapshot = await getDocs(q);
  let posts = [];

  await Promise.all(querySnapshot.docs.map(async (doc) => {
    let sections = []
    const qsections = query(collection(db, "posts",  doc.id, "sections"), 
      orderBy('no', 'desc')
    );
    const querySectionsSnapshot = await getDocs(qsections);
    querySectionsSnapshot.forEach((docSection) => {
      sections.push({
        postSectionId: docSection.id,
        ...docSection.data(),
        createdAt: docSection.data().createdAt.toDate(),
        updatedAt: docSection.data().updatedAt.toDate(),
      });
    });

    posts.push({
      postId: doc.id,
      ...doc.data(),
      sections,
      publishDate: doc.data().publishDate ? doc.data().publishDate.toDate() : undefined,
      //createdAt: doc.data().createdAt ? doc.data().createdAt.toDate() : undefined,
      updatedAt: doc.data().updatedAt ? doc.data().updatedAt.toDate() : undefined,
    })
  }));
  return posts;
};

export const postDetail = async (postId) => {
  debugger
  let categories = [];

  const q = query(collection(db, "categories"));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    categories.push({
      categoryId: doc.id,
      ...doc.data(),
    });
  });

  let posts = [];
  const qPosts = query(collection(db, "posts"));
  const querySnapshotPosts = await getDocs(qPosts);

  querySnapshotPosts.forEach((doc) => {
    posts.push({
      postId: doc.id,
      ...doc.data(),
      categoryObj: categories.find(item => item.categoryId === doc.data().category),
      categoryParentObj: categories.find(item => item.categoryId === doc.data().categoryParent),
      publishDate: doc.data().publishDate.toDate(),
    });
  });

  let tags = [];
  const qTags = query(collection(db, "tags"));
  const querySnapshotTags = await getDocs(qTags);

  querySnapshotTags.forEach((doc) => {
    tags.push({
      tagId: doc.id,
      ...doc.data()
    });
  });


  let authors = [];
  const qAuthors = query(collection(db, "authors"));
  const querySnapshotAuthors = await getDocs(qAuthors);

  querySnapshotAuthors.forEach((doc) => {
    authors.push({
      authorId: doc.id,
      ...doc.data(),
    });
  });

  let post = {};

  const docRef = doc(db, "posts", postId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    post = docSnap.data();
    post.postId = docSnap.id;

    // posts array of object
    const postsId = docSnap.data().relatedPosts || [];
    const relatedPosts = posts.filter(item => {
      const obj = postsId.find(x => x === item.postId);
      if (obj) return item
    });

    // tags array of object
    const tagsId = docSnap.data().tags || [];
    const tagsObj = tags.filter(item => {
      const obj = tagsId.find(x => x === item.tagId);
      if (obj) return item
    });

    post.relatedPosts = relatedPosts;
    post.tags = tagsObj;
    post.categoryObj = categories.find(item => item.categoryId === post.category);
    post.categoryParentObj = categories.find(item => item.categoryId === post.categoryParent);

    post.author = authors.find(item => item.authorId === post.author);
    post.createdAt = post.createdAt.toDate();
    post.publishDate = post.publishDate.toDate();

    // sections
    let sections = [];
    // let sectionsDoc = await firestore.collection("posts")
    //   .doc(doc.id).collection("sections")
    //   .orderBy("no", "asc")
    //   .get();
    const querySections = await getDocs(collection(db, "posts", docSnap.id, "sections"), orderBy("no", "asc"));
    querySections.forEach((doc) => {
      sections.push({
        postSectionId: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate(),
        updatedAt: doc.data().updatedAt.toDate()
      });
    });

    post.sections = sections;

    return post;
  } else {
    return undefined;
  }
};

export const postCreate = async (payload) => {
  try {
    const docRef = await addDoc(collection(db, "posts"), {
      ...payload,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
    return {
      status: 'success',
      id: docRef.id
    };
  } catch (error) {
    return {
      status: 'error',
      error,
    };
  }
};

export const postUpdate = async (payload) => {
  try {
    const postId = payload?.postId;
    delete payload.postId;
    await updateDoc(doc(db, "posts", postId), {
      ...payload,
      updatedAt: serverTimestamp(),
    });
    return {
      status: 'success',
      id: postId
    };
  } catch (error) {
    return {
      status: 'error',
      error,
    };
  }
};

export const postSectionCreate = async (payload) => {
  try {
    const docRef = doc(db, "posts", payload?.postId);
    const docSnap = await getDoc(docRef);

    //insert to sections
    if (docSnap.exists()) {
      const docSectionRef = await addDoc(collection(db, "posts", payload?.postId, "sections"), {
        ...payload,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
      return {
        status: 'success',
        postSectionId: docSectionRef.id
      };
    }
  } catch (error) {
    return {
      status: 'error',
      error,
    };
  }
};

export const postSectionUpdate = async (payload) => {
  try {
    const docRef = doc(db, "posts", payload?.postId);
    const docSnap = await getDoc(docRef);

    //update to sections
    if (docSnap.exists()) {
      await updateDoc(doc(db, "posts", payload?.postId, "sections", payload.postSectionId), {
        ...payload,
        updatedAt: serverTimestamp(),
      });
      return {
        status: 'success',
        postSectionId: payload.postSectionId
      };
    }
  } catch (error) {
    return {
      status: 'error',
      error,
    };
  }
};

export const postSectionDelete = async (payload) => {
  try {
    const docRef = doc(db, "posts", payload?.postId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      await deleteDoc(doc(db, "posts", payload?.postId, "sections", payload.postSectionId));
      return {
        status: 'success',
        postSectionId: payload.postSectionId
      };
    }
  } catch (error) {
    return {
      status: 'error',
      error,
    };
  }
};
