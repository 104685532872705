import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Routes, Route } from 'react-router-dom';
import Admin from 'pages/home';

//Private - Admin
import AdminLayout from 'layouts/admin';
import Tutorials from 'pages/admin/tutorials/tutorials';
import Versions from 'pages/admin/versions';
import Announcements from 'pages/admin/announcements/announcements';
import Users from 'pages/admin/users';
import Teams from 'pages/admin/users/teams';
import Usage from 'pages/admin/usage';
import Billings from 'pages/admin/billings';
import Reports from 'pages/admin/reports';
import Contacts from 'pages/admin/contacts/contacts';
import SuperUsers from 'pages/admin/superusers';
import useAuth from 'hooks/useAuth';
import SidebarLayout from 'layouts/sidebar';
import ForgotPassword from 'pages/forgot';
import Subscriptions from 'pages/admin/subscriptions';
import UserUsageDetail from 'pages/admin/users/usage-detail';
import ManualInvoices from 'pages/admin/users/manual-invoices';

const App = () => {
  const { user } = useAuth();

  return (
    <>
      <Helmet>
        <title>Neep Admin</title>
      </Helmet>
      <Routes>
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route index={true} path="/" element={<Admin />} />
        <Route path="v1" element={<AdminLayout />}>
          {user?.role === "super" && (
            <>
              <Route index element={<Users />} />
              <Route path="users">
                <Route index element={<Users />} />
                <Route path="teams" element={<Teams />} />
                <Route path="manual-invoices" element={<ManualInvoices />} />
                <Route path="usage-detail/:userId" element={<UserUsageDetail />} />
              </Route>
              <Route path="usages">
                <Route index element={<Usage />} />
              </Route>
              <Route path="billings" element={<Billings />} />
              <Route path="subscriptions" element={<Subscriptions />} />
              <Route path="master" element={<SidebarLayout role={user?.role} />}>
                <Route path="tutorials" element={<Tutorials />} />
                <Route path="versions" element={<Versions />} />
                <Route path="announcements" element={<Announcements />} />
                <Route path="superusers" element={<SuperUsers />} />
              </Route>
              <Route path="reports" >
                <Route index element={<Reports />} />
              </Route>
              <Route path="contacts" element={<Contacts />} />
            </>
          )}

          {user?.role === "sale" && (
            <>
              <Route index element={<Users />} />
              <Route path="users" element={<Users />} />
              <Route path="usages">
                <Route index element={<Usage />} />
              </Route>
              <Route path="billings" element={<Billings />} />
              <Route path="contacts" element={<Contacts />} />
            </>
          )}

          {user?.role === "support" && (
            <>
              <Route path="usages">
                <Route index element={<Usage />} />
              </Route>
              <Route path="reports" >
                <Route index element={<Reports />} />
              </Route>
            </>
          )}
        </Route>
      </Routes>
    </>
  );
};

export default App;
