import React, { useState, useEffect } from 'react';
import './styles.less';

import { Modal, Button, Table, Card, Row, Col, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { versionList, versionDetail } from 'apis/version';

import VersionCreateForm from './forms/version-create.form';
import VersionUpdateForm from './forms/version-update.form';

const Versions = () => {
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [version, setVersion] = useState({});
  const [versions, setVersions] = useState([]);
  const [minBuild, setMinBuild] = useState(0);

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    versionList()
      .then(result => {
        setVersions(result)
        setLoading(false)
        setMinBuild(Math.max(...result.map(item => item.builderNumber)) + 1);
      });
  }, []);

  const openUpdateModal = (versionId) => {
    const version = versions.find(item => item.versionId === versionId);
    setVersion(version);
    setIsUpdateModal(true);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    {
      title: 'Os',
      dataIndex: 'os',
      width: '4%'
    },
    {
      title: 'Version',
      dataIndex: 'version',
      width: '6%'
    },
    {
      title: 'Builder Number',
      dataIndex: 'builderNumber',
      width: '7%'
    },
    {
      title: 'Name',
      width: '20%',
      render: (record) => {
        return (
          <div style={{display: 'flex', flexDirection: 'column'}}>
            {record?.isPreviewUpdate && <Tag style={{width: 110, textAlign: 'center', marginBottom: 5}} color='#108ee9'>Preview Update</Tag>}
            {record?.isForceUpdate && <Tag style={{width: 110, textAlign: 'center', marginBottom: 5}} color='#f50'>Force Update</Tag>}
            {record?.nameEn}
          </div>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      align: 'center',
      width: '4%',
      render: (record) => {
        if (record === true)
          return (
            <Tag>Active</Tag>
          )
        else {
          return (
            <Tag color={'red'}>In-active</Tag>
          )
        }
      }
    },
    {
      title: '',
      align: 'center',
      width: '10%',
      render: (record) => {
        return (
          <Button type="primary" onClickCapture={() => openUpdateModal(record.versionId)}>Edit</Button>
        );
      }
    }
  ];

  return (
    <>
      <Card style={{ marginBottom: 18 }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
          <Col className="gutter-row" span={12}>
            <h2>Version</h2>
          </Col>
          <Col className="gutter-row" span={12} style={{ textAlign: 'right' }}>
            <Button type="primary" onClick={() => setIsCreateModal(true)} icon={<PlusOutlined />}>Thêm mới</Button>
          </Col>
        </Row>
      </Card>
      <Table
        loading={loading}
        bordered
        columns={columns}
        dataSource={versions}
        onChange={(pagination, filters, sorter) => {
          setCurrentPage(pagination.current);
        }}
        pagination={{
          total: versions.length,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
          pageSize: 20,
          current: currentPage
        }}
      />
      <Modal
        open={isCreateModal}
        title="Version / Create"
        onCancel={() => setIsCreateModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <VersionCreateForm
          onCancel={() => setIsCreateModal(false)}
          onCallback={() => {
            setLoading(true)
            versionList()
            .then(result => {
              setVersions(result)
              setLoading(false)
              setMinBuild(Math.max(...result.map(item => item.builderNumber)) + 1);
            });

            setIsCreateModal(false)
          }}
          minBuild={minBuild}
        />
      </Modal>
      <Modal
        open={isUpdateModal}
        title="Version / Update"
        onCancel={() => setIsUpdateModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <VersionUpdateForm
          version={version}
          onCancel={() => setIsUpdateModal(false)}
          onCallback={() => {
            setLoading(true)

            versionList()
            .then(result => {
              setVersions(result)
              setLoading(false)
              setMinBuild(Math.max(...result.map(item => item.builderNumber)) + 1);
            });

            setIsUpdateModal(false)
          }}
        />
      </Modal>
    </>
  )
};

export default Versions;