import React, { useState } from 'react';

import { Form, Input, Col, Row, Button, DatePicker, Select, InputNumber, Checkbox } from 'antd';
import { userUpdate } from 'apis/user';
import moment from 'moment';

const UserUpdateForm = ({ user, onCancel, onCallback }) => {
  const [loading, setLoading] = useState(false);
  const [manualInvoice, setManualInvoice] = useState(user?.source === "manual-invoice" || false);

  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      className="post-modal"
      layout="vertical"
      onFinish={values => {
        setLoading(true);

        const item = {
          userId: user?.userId
        };

        if (values?.endTime) {
          item.endTime = values?.endTime;
        }

        if (values?.manualInvoice && values?.seat > 1) {
          item.seat = values?.seat;
          item.source = "manual-invoice";
        }

        // if (values?.source) {
        //   item.source = values?.source;
        // }

        userUpdate(item)
          .then(() => {
            setLoading(false);
            onCancel();
            if (values?.manualInvoice && values?.seat > 1) {
              onCallback(values?.endTime, "manual-invoice", values?.seat);
            } else {
              onCallback(values?.endTime, values?.source);
            }
          });
      }}
      initialValues={{
        ...user,
        endTime: moment(user?.endTime),
        manualInvoice: user?.source === "manual-invoice" || false,
      }}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="email"
            label="Email"
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            label="EndTime"
            name="endTime"
            rules={[
              {
                required: true,
                message: 'EndTime!'
              }
            ]}
          >
            <DatePicker style={{ width: '100%' }} placeholder="EndTime" />
          </Form.Item>
        </Col>
        {!(user?.subscription && user?.subscription?.status === "active") && !user?.teamId && (
          <>
            <Col lg={{ span: 12 }} xs={{ span: 24 }}>
              <Form.Item
                name="manualInvoice"
                label="Manual Invoice"
                valuePropName="checked"
              >
                <Checkbox onChange={e => setManualInvoice(e.target.checked)} />
              </Form.Item>
            </Col>
            {manualInvoice && (
              <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                <Form.Item
                  label="Seat"
                  name="seat"
                  rules={[
                    {
                      required: true,
                      message: "Seat!"
                    }
                  ]}
                >
                  <InputNumber min={2} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            )}
          </>
        )}
        {/* <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item 
              label="Source"
              name="source"
            >
              <Select
                placeholder="Source"
              >
                <Select.Option value=""></Select.Option>
                <Select.Option value="appsumo">Appsumo</Select.Option>
                <Select.Option value="giveaway">Giveaway</Select.Option>
                <Select.Option value="gumroad">Gumroad</Select.Option>
                <Select.Option value="enterprise">Enterprise</Select.Option>
              </Select>
            </Form.Item>
          </Col> */}
      </Row>

      <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}>
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={12} style={{ textAlign: 'left', paddingLeft: 20 }}>
          </Col>
          {loading === false ?
            <Col className="gutter-row" span={12} style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Col>
            :
            <Col className="gutter-row" span={12} style={{ textAlign: 'right' }}>
              <Button type="primary" loading>
                Save
              </Button>
            </Col>
          }
        </Row>
      </div>

    </Form>
  );
};

export default UserUpdateForm;